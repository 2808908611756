import React from "react"
import GameCard from "../smallGameCard"
import { Link } from "gatsby"
import Style from "./gameCategoryWrapper.module.scss"
function GameCategoryWrapper({ category, data, viewAll, gameLink }) {
  const categoryCap = category[0].toUpperCase() + category.slice(1)
  const showData = viewAll ? data : data.slice(0, 6)
  return (
    <div className={Style.mainContainer}>
      <div className={Style.gameWrapperHeading}>
        <div className={Style.heading}>
          <h2 className={Style.categHeading}>{categoryCap}</h2>
          <span className={Style.gameCount}>{`${data.length} games`} </span>
        </div>
        {!viewAll && (
          <Link to={`/${category.toLowerCase()}/`} className={Style.viewAll}>
            View All
          </Link>
        )}
      </div>
      <div className={Style.gameCardWrapper}>
        {showData.map((d, i) => (
          <GameCard key={i} data={d.node} key={i} gameLink={gameLink} />
        ))}
      </div>
    </div>
  )
}

export default GameCategoryWrapper
