import React from "react"
import style from "./smallGameCard.module.scss"
import { GatsbyImage } from "../GatsbyImage"
import { Link } from "gatsby"
function SmallGameCard({ data, gameLink }) {
  console.log(data)
  const slicedName = data.name.slice(0, 11)
  const link = gameLink ? `${gameLink}/${data.link}` : data.link
  return (
    <Link className={style.gameCard} to={link}>
      <GatsbyImage className={style.img} name={data.image} alt={data.name} />
      <div className={style.gameNameContainer}>
        <h2 className={style.gameName}>{`${slicedName}..`}</h2>
        {/* <p className={style.subtext}>71k Plays</p> */}
      </div>
    </Link>
  )
}

export default SmallGameCard
